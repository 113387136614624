import {useState, useEffect} from 'react'

export const LocationPrompt = (): boolean => {
	const [isLocationAllowed, setIsLocationAllowed] = useState<boolean>(false)

	useEffect(() => {
		const checkLocationPermission = (): void => {
			navigator.geolocation.getCurrentPosition(
				() => {
					setIsLocationAllowed(true)
				},
				() => {
					setIsLocationAllowed(false)
				}
			)
		}

		checkLocationPermission()
	}, [])

	return isLocationAllowed
}
